import * as Sentry from "@sentry/nuxt";
import { useExtractQueryParams } from "~/composables";

export default defineNuxtRouteMiddleware((to) => {
  const { $pinia, $i18n } = useNuxtApp();
  const { userType, user } = storeToRefs(useUserStore($pinia));

  if (userType.value !== "guest") {
    return;
  }

  const { registrationParams } = storeToRefs(useOnboardingStore($pinia));
  const biTrackingStore = useBiTrackingStore($pinia);

  const { getUndefinedMandatoryRegistrationParams } = useExtractQueryParams();

  const undefinedMandatoryParams = getUndefinedMandatoryRegistrationParams(registrationParams.value);

  if (undefinedMandatoryParams.length) {
    const userContext = {
      Sex: registrationParams?.value?.sex || "-",
      Goal: registrationParams?.value?.goal || "-",
      Pro: !!(user?.value && user?.value?.premium_type),
      Country: registrationParams?.value?.country || "-",
      Language: $i18n?.locale?.value || "-",
      "Session ID": biTrackingStore?.getSessionId() || "-",
      "User UUID": registrationParams?.value?.userUuid || "-",
      "User Type": userType?.value || "-",
    };
    Sentry.captureMessage("Checkout attempt has missing mandatory registration params.", {
      extra: {
        undefinedMandatoryParams: undefinedMandatoryParams.toString(),
        requestURL: to.fullPath,
        ...userContext,
      },
    });
  }
});
